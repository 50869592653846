








































































































































































































































































































































.listWrapped .giftName {
  min-width: 100px !important;
  .el-input__inner {
    cursor: pointer;
    border: 0;
    text-align: center;
  }
}
.listWrapped .giftPrice {
  min-width: 100px !important;
  // cursor: pointer;
  .el-input__inner {
    cursor: pointer;
    border: 0;
    text-align: center;
  }
}
.videoShow {
  width: 100%;
  height: 100%;
}
