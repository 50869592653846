<template>
  <div>
    <el-form
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      label-position="left"
      label-width="100px"
      size="small"
      enctype="multipart/form-data"
    >
      <el-form-item label="礼物名字" prop="name" required>
        <el-input
          v-model="dataForm.name"
          id="write"
          placeholder="请输入礼物名字"
        ></el-input>
      </el-form-item>
      <el-form-item label="礼物价格" prop="price">
        <el-input
          v-model="dataForm.price"
          id="write"
          placeholder="请输入礼物价格 (单位：元)"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="直播间密码" prop="password">
        <el-input v-model="dataForm.password" placeholder="请输入"></el-input>
      </el-form-item> -->
      <el-form-item label="礼物视频" prop="video" style="width: 600px" required>
        <el-upload
          class="mdr5"
          action=""
          :http-request="addhandleVideoSuccess"
          list-type="picture-card"
          :before-upload="beforeUploadVideo"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <video
          style="
            position: absolute;
            top: 0px;
            left: 250px;
            width: 200px;
            height: 150px;
          "
          v-if="dataForm.video"
          :src="dataForm.video"
          autoplay="autoplay"
          class="avatar"
          controls="controls"
        ></video>
        <i
          v-if="dataForm.video"
          @click="onaddvideoForm"
          style="position: absolute; top: -5px; left: 445px"
          class="el-icon-error"
        ></i>
      </el-form-item>
      <el-form-item
        label="礼物封面"
        prop="giftCover"
        style="width: 600px"
        required
      >
        <el-upload
          refs="upaddGoodsRead"
          action=""
          :limit="1"
          :http-request="upaddGoodsRead"
          list-type="picture-card"
          :on-remove="addhandleRemoveGoodsRead"
          :before-upload="beforeAvatarUpload"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="btnSubmit()">提交</el-button>
        <el-button @click="cancelDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { insertGiftInfo } from "../api/index";
export default {
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!/^[a-zA-Z0-9\u4e00-\u9fa5]*$/.test(value)) {
        callback(new Error("请填写正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      fileListForPersonalImage: [],
      pageType: "add",
      // addvideoForm: {
      //   Video: "",
      // },
      rules: {
        name: [{ required: true, message: "请输入礼物名字", trigger: "blur" }],
        price: [
          {
            required: true,
            message: "请输入礼物价格",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]*$/,
            message: "只能输入数字",
            trigger: "change",
          },
        ],
        giftCover: [
          {
            required: true,
            message: "请添加礼物封面",
            trigger: "blur",
          },
        ],
        video: [
          {
            required: true,
            message: "请添加礼物视频",
            trigger: "blur",
          },
        ],
      },
      dataForm: {
        giftCover: null,
        name: null,
        video: "",
        price: null,
      },
    };
  },
  methods: {
    cancelDialog() {
      console.log(this.$parent, "this.$parent");
      this.$parent.$parent.cancelDialog();
    },
    onaddvideoForm() {
      this.dataForm.video = "";
      this.dataForm.videoUploadId = "";
    },
    // 视频上传成功
    async addhandleVideoSuccess(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      const { data: res } = await this.$http.post(
        `/api/platform/uploadVideo`,
        formData
      );
      if (res.code == 200) {
        //  this.images.push(res.result.url)
        this.dataForm.video = res.body;
      } else {
        this.$message.error("视频上传失败，请重新上传！");
      }
    },
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");

        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过20MB哦!");
        return false;
      }
    },
    //图片上次cdn
    async upaddGoodsRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("type", 1);
      const { data: res } = await this.$http.post(
        `/api/platform/uploadImages`,
        formData
      );
      if (res.code !== 200) {
        this.$message.error("上传失败");
        this.$refs.upaddGoodsRead.uploadFiles.splice(0, 1);
      } else {
        this.dataForm.giftCover = res.body[0].split("?")[0];

        this.$message.success("上传成功");
      }
    },
    addrevampGoodsRead() {},
    //删除图片
    addhandleRemoveGoodsRead() {
      this.dataForm.giftCover = "";
    },
    // 上传图片验证
    beforeAvatarUpload(file) {
      if (!(file.type === "image/jpeg" || file.type === "image/png")) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
      if (!(file.size / 1024 / 1024 < 5)) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return false;
      }
      return this.imgchecked(file);
    },
    imgchecked(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file); // 必须用file.raw
        reader.onload = () => {
          // 让页面中的img标签的src指向读取的路径
          let img = new Image();
          img.src = reader.result;
          img.onload = () => {
            if (!(480 <= img.width <= 1242 && img.height <= 1920)) {
              this.$message.error(
                "480px≤图片宽度≤1242px(图片宽度建议上传750)，0<图片高度≤1920px"
              );
              reject();
            } else {
              resolve(true);
            }
          };
        };
      });
    },

    // 表单提交
    btnSubmit() {
      console.info(this.dataForm);
      // let extendsObj = {
      //   userAvatar: window.sessionStorage.getItem("giftCover"),
      //   userNick: window.sessionStorage.getItem("userName"),
      // };
      let obj = {
        // anchor: window.sessionStorage.getItem("userId"),
        // anchor_nick: window.sessionStorage.getItem("userName"),
        icon: this.dataForm.giftCover,
        // extends: "",
        enabled: 0,
        price: this.dataForm.price,
        name: this.dataForm.name,
        specialEffects: this.dataForm.video,
      };
      // if (!obj.password) {
      //   delete obj.password;
      // }

      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          const { data } = await insertGiftInfo(obj);
          if (data.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.$parent.hide();
            this.$emit("fetchList");
          }
          // window.sessionStorage.setItem("chat_id", data.chat_id);
        }
      });
    },

    cancelBtn() {
      this.$parent.hide();
    },
  },
};
</script>

<style>
.mdr5 .el-upload-list {
  display: none;
}
</style>
